import React from "react";
import "./App.css";
import "@vseth/vseth-theme/dist/vseth-bootstrap-theme.css";

import { HelloPage } from "./pages/HelloPage";
import { VSETHNavbar } from "@vseth/components";

const primaryItems = [{
  title: "Hochschulpoilitik",
  href: "#hopo"
}]

const App: React.FC = () => {
  return (
    <div>
      <div className="App-header mobile-capable">
        <VSETHNavbar primaryActionItems={primaryItems}></VSETHNavbar>
      </div>
    <HelloPage></HelloPage>
  </div>);
};

export default App;