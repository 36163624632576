export const i18n_german = {
  translation: {
    lang: "de",
    example: {
      title: "Übersetzungen sind grossartig!",
      introduction:
        "Viele Angehörige der ETH Zürich sprechen kein Deutsch, deshalb ist es sehr wichtig, dass die IT Tools in mehrere Sprachen übersetzt werden. Dieses Template zeigt wie das gemacht werden kann.",
      buttonText: "Sprache wechseln"
    }
  }
};
