export const i18n_english = {
  translation: {
    lang: "en",
    example: {
      title: "Translations are important!",
      introduction:
        "A lot of members of ETH Zurich do not speak german, so it is very important for the IT to tools that can be easily translated into multiple languages. This template showcases how this can be achieved.",
      buttonText: "Change language"
    }
  }
};
