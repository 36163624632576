import React from "react";
import {
  Container
} from "@vseth/components";

export const HelloPage = () => {
  return (
    <div>
      <section  className="ressort-wrapper">
        <Container>
          <h1>Der VSETH sucht Vorstände!</h1>
          <p className="lead">
            Hilf uns, den Dachverband VSETH zu leiten.
          </p>
          <p>
            In wöchentlichen Vorstandssitzungen prägst du das operative Geschäft des Verbandes.<br/>
            Mit deinem Ressort ermöglichst du den Studenten ein angenehmeres und abwechslungsreicheres Studium.  
          </p>
        </Container>
      </section>

      <section className="banner-wrapper">
        <div className="row">
          <div className="col-md-6">  
            <img className="banner-image" src="/images/beaver.jpg" alt="beaver"></img>
          </div>
          <div className="col-md-6 bg-primary motivation-wrapper">
            <p className="motivation">Gemeinsam Bäume ausreissen?</p>
          </div>
        </div>
      </section>

      <section className="ressort-wrapper">
        <Container>
          <h1>Hochschulpolitik</h1>
          <p className="lead">
          Vertrete die Interessen aller Studierenden auf der höchsten hochschulpolitischen Ebene.
          </p>
          <p>
            Bringe die Anliegen der Studierenden in ETH Gremien.<br/>
            Verfasse Massnahmenpapiere um die psychologische Gesundheit der Studierenden zu verbessern.<br/>
            Schreibe Stellungnahmen um Einfluss auf ETH Regelementen zu nehmen.<br/>
          </p>
        </Container>
      </section>

      <section className="banner-wrapper">
        <div className="row">
          <div className="col-md-6">  
            <img className="banner-image" src="/images/penguins.jpg" alt="penguins"></img>
          </div>
          <div className="col-md-6 bg-primary motivation-wrapper">
            <p className="motivation">Nicht immer nur in die Vorlesung watscheln?</p>
          </div>
        </div>
      </section>

      <section className="ressort-wrapper">
        <Container>
            <h1>Dienstleistungen</h1>
            <p className="lead">
            Ermögliche die Arbeit von Fachvereinen, Kommissionen und studentischen Organisationen. 
            </p>
            <p>
              Betreue die Werbekanäle die über das Angebot von Studentenorganisationen zu informieren.<br/>
              Hilf Studentenorganisationen bei der Organisation von sich selbst und ihren Dienstleistungen<br/>
              Bringe die Engagierten des VSETH näher zusammen.
            </p>
        </Container>
      </section>

      <section className="banner-wrapper">
        <div className="row">
          <div className="col-md-6">  
            <img className="banner-image" src="/images/sloth.jpg" alt="sloth"></img>
          </div>
          <div className="col-md-6 bg-primary motivation-wrapper">
            <p className="motivation">Mehr Action in deinem Studienalltag?</p>
          </div>
        </div>
      </section>

      <section className="ressort-wrapper">
        <Container>      
          <section>
            <h1>Projekte</h1>
            <p className="lead">
            Organisiere die grössten Projekte und Events an der ETH.
            </p>
            <p>
              Das Erstsemestrigenfest auf dem Höngg besuchen 5000 Studierende.<br/>
              An der Summerbar und in der Nik’s Hütte gibts Bier, Glühwein uns alles dazwischen.<br/>
              Beim Conquering wird Zürich von einer etwas anderen Seite kennengelernt.
            </p>
          </section>
        </Container>
      </section>
      
      <section className="banner-wrapper">
        <div className="row">
          <div className="col-md-6">  
            <img className="banner-image" src="/images/parrot.jpg" alt="parrot"></img>
          </div>
          <div className="col-md-6 bg-primary motivation-wrapper">
            <p className="motivation">Für alle bunten Vögel die mehr als plappern wollen.</p>
          </div>
        </div>
      </section>

      <section className="ressort-wrapper">
        <Container>      
          <section>
            <h1>Interessiert?</h1>
            <p className="lead">
              Wir erzählen dir gerne mehr unter <a href="mailto:hallo@vseth.ethz.ch">hallo@vseth.ethz.ch</a>.
            </p>
            <p>
              Wirke in der Hochschulpolitik mit im Ressort <a href="mailto:hopo@vseth.ethz.ch">HoPo</a>.<br/>
              Verwalte die Dienstleistungen als <a href="mailto:kommunikation@vseth.ethz.ch">Kommunikations</a>- und <a href="mailto:ia@vseth.ethz.ch">Internal Affairs</a> Vorstand.<br/>
              Hilf dem Ressort <a href="mailto:projekte@vseth.ethz.ch">Projekte</a> bei der Organisation von Events.
            </p>
          </section>
        </Container>
      </section>
    </div>
  );
};
